// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Home from "./Components/Home/Home";
import Tagging from "./Components/Tagging/Tagging";
import Clients from './Components/Clients/Clients';
import Login from './Components/Login/Login';
import Layout from './Components/Layout/Layout';
import ProtectedRoute from './ProtectedRoute';
import ClientCostDetails from './Components/ClientCostDetails/ClientCostDetails';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Layout><Home /></Layout>} />
            <Route path="/tagging" element={<Layout><Tagging /></Layout>} />
            <Route path="/clients" element={<Layout><Clients /></Layout>} />
            <Route path="/cost/:client/:type/:month" element={<Layout><ClientCostDetails /></Layout>} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}
