import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
    Pagination,
} from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Cell,
} from 'recharts';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import api from '../../Api';

// Color palette for the bars
const blues: string[][] = [
    ["#457AA6"],
    ["#457AA6", "#E3EBF2"],
    ["#264F73", "#457AA6", "#E3EBF2"],
    ["#264F73", "#457AA6", "#A2BBD2", "#E3EBF2"],
    ["#1A334A", "#264F73", "#457AA6", "#A2BBD2", "#E3EBF2"]
];

// Get the color based on the length and index
const getColor = (length: number, index: number): string => {
    return length <= blues.length ? blues[length - 1][index] : blues[blues.length - 1][index % blues.length];
};

// Props for Y-axis ticks
interface YAxisTickProps {
    x: number;
    y: number;
    payload: { value: string };
    costtype: string;
    month: string;
    onClick: (path: string) => void;
}

// Y-axis tick component
const YAxisTick: React.FC<YAxisTickProps> = ({ x, y, payload, costtype, month, onClick }) => (
    <text
        x={x}
        y={y}
        fill="#3f51b5" // Base color: a rich blue
        textAnchor="end"
        dy={16}
        onClick={() => onClick(`/cost/${payload.value}/${costtype}/${month}`)} // Navigate to the corresponding path
        style={{
            cursor: 'pointer',
            fontWeight: 'bold', // Bold text
            transition: 'color 0.3s ease', // Smooth transition effect
        }}
        onMouseEnter={(e) => (e.currentTarget.style.fill = '#f50057')} // Hover color: bright pink
        onMouseLeave={(e) => (e.currentTarget.style.fill = '#3f51b5')} // Revert to blue on mouse leave
    >
        {payload.value}
    </text>
);

// Context for measuring text
let ctx: CanvasRenderingContext2D | null;

// Measure text width using canvas
export const measureText14HelveticaNeue = (text: string): number => {
    if (!ctx) {
        ctx = document.createElement("canvas").getContext("2d");
        if (ctx) ctx.font = "14px 'Helvetica Neue'";
    }
    return ctx?.measureText(text).width || 0;
};

const BAR_AXIS_SPACE = 10; // Space between bars and axis

// Interface for response data structure
interface ResponseData {
    CostCenter: string;
    Cost: number;
}

// Props for CostByClient component
interface CostByClientProps {
    costtype: string;
    month: string;
}

export default function CostByClient({ costtype, month }: CostByClientProps) {
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState<ResponseData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1); // Current page
    const itemsPerPage = 6; // Number of items per page

    // Fetch data when cost type or month changes
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response: AxiosResponse<ResponseData[]> = await api.get(`/api/cost/client?type=${costtype}&month=${month}`);
                setResponseData(response.data);
            } catch (error) {
                console.error('Error fetching cost by client:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [costtype, month]);

    // Handle click on Y-axis tick
    const handleYAxisClick = (path: string) => {
        navigate(path); // Navigate to the clicked Y-axis label's path
    };

    // Handle page change
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); // Change current page
    };

    // Determine maximum text width for Y-axis labels
    const maxTextWidth = Math.max(...responseData.map(item => measureText14HelveticaNeue(item.CostCenter))) || 0;
    const xKey = 'CostCenter'; // Key for X-axis data
    const yKey = 'Cost'; // Key for Y-axis data

    // Calculate current items for the current page
    const startIndex = (page - 1) * itemsPerPage;
    const currentData = responseData.slice(startIndex, startIndex + itemsPerPage);
    
    // Total number of pages
    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    return (
        <Card elevation={3} sx={{ padding: 2, margin: 2, minHeight: 480 }}>
            <CardHeader
                title="Cost by Client"
                subheader="Cost split by clients"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                subheaderTypographyProps={{ variant: 'subtitle2' }}
            />
            <CardContent>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                        <Typography variant="body2" sx={{ ml: 2 }}>Loading...</Typography>
                    </Box>
                ) : currentData.length > 0 ? (
                    <>
                        <ResponsiveContainer width="100%" height={50 * currentData.length} debounce={50}>
                            <BarChart
                                data={currentData}
                                layout="vertical"
                                margin={{ left: 10, right: maxTextWidth + (BAR_AXIS_SPACE - 8) }}
                            >
                                <XAxis hide axisLine={false} type="number" />
                                <Bar dataKey={yKey} minPointSize={2} barSize={32}>
                                    {currentData.map((d, idx) => (
                                        <Cell key={d[xKey]} fill={getColor(currentData.length, idx)} />
                                    ))}
                                </Bar>
                                <YAxis
                                    yAxisId={0}
                                    dataKey={xKey}
                                    type="category"
                                    axisLine={false}
                                    tickLine={false}
                                    orientation="right"
                                    tick={(props) => (
                                        <YAxisTick
                                            {...props}
                                            costtype={costtype}
                                            month={month}
                                            onClick={handleYAxisClick}
                                        />
                                    )}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                        <Box display="flex" justifyContent="center" mt={2}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                ) : (
                    <Typography>No data available</Typography>
                )}
            </CardContent>
        </Card>
    );
}
