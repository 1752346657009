// Components/Layout/Layout.tsx
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Box, Grid, Container, Link, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';
import BreadCrumb from '../BreadCrumb/BreadCrumb';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', minHeight: '100vh', background: 'linear-gradient(180deg,#270067 48.96%,#8626c0)', }}>
            <Sidebar />
            <Box sx={{
                background: 'white',
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                marginTop: '10px',
                overflowY: 'auto',
                maxHeight: 'calc(98vh)',
                minWidth: 'calc(100vh - 80px)',
                width: { xs: '100%', lg: '198vh' },
                paddingLeft: '20px'
            }}>
                <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: 'white', paddingTop: '20px' }}>
                    <Typography variant='h5' padding={3}>Cloud Costs</Typography>
                    <BreadCrumb />
                    <Divider></Divider>
                </Box>
                <Box padding={1}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default Layout;
