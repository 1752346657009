import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import api from '../../Api';

const COLORS = {
  azure: '#8884d8',
  aws: '#82ca9d',
  googleCloud: '#FF8042'
};

export default function CostByService() {
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/cost/monthly`);
        setResponseData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Card elevation={3} sx={{ padding: 2, margin: 2 }}>
      <CardHeader
        title="Monthly Cost Trend by Service"
        subheader="Tracking costs for Azure, AWS, and Google Cloud"
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
        subheaderTypographyProps={{ variant: 'subtitle2' }}
      />
      <CardContent>
        {responseData.length > 0 ? (
          <ResponsiveContainer width="100%" height={350}>
            <LineChart
              data={responseData}
              margin={{
                top: 20, right: 30, left: 10, bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
              <XAxis dataKey="month" tick={{ fontSize: 12, fontWeight: 500 }} />
              <YAxis tick={{ fontSize: 12, fontWeight: 500 }} />
              <Tooltip
                contentStyle={{ backgroundColor: '#f5f5f5', borderRadius: '8px', border: 'none' }}
                cursor={{ stroke: 'rgba(0, 0, 0, 0.1)', strokeWidth: 2 }}
              />
              <Legend wrapperStyle={{ fontSize: '12px', fontWeight: '500' }} />
              <Line type="monotone" dataKey="azure" stroke={COLORS.azure} strokeWidth={3} dot={{ r: 5 }} activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="aws" stroke={COLORS.aws} strokeWidth={3} dot={{ r: 5 }} activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="googleCloud" stroke={COLORS.googleCloud} strokeWidth={3} dot={{ r: 5 }} activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1" align="center" color="textSecondary">
            No data available.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
