import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText } from '@mui/material';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import api from '../../../Api';

const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
    '#FF5733', '#DAF7A6', '#BDBDC3', '#C70039',
    '#900C3F', '#581845'
];

interface DataItem {
    Service: string;
    Cost: string;
}

interface CostByServiceProps {
    costtype: string;
    client: string;
    month: string;
}

const CostByService: React.FC<CostByServiceProps> = ({ costtype, client, month }) => {
    const [responseData, setResponseData] = useState<DataItem[]>([]);
    const [sortedData, setSortedData] = useState<DataItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/api/cost/client/service?name=${client}&type=${costtype}&month=${month}`);
                const sortedData = response.data.sort((a: DataItem, b: DataItem) => parseFloat(b.Cost) - parseFloat(a.Cost));
                setResponseData(response.data);
                setSortedData(sortedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [client, costtype, month]);

    return (
        <Card sx={{ margin: 2, borderRadius: '10px', boxShadow: 3 }}>
            <CardHeader
                title="Cost by Service"
                subheader="Distribution of costs across different services"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    dataKey="Cost"
                                    data={responseData}
                                    nameKey="Service"
                                    outerRadius={90}
                                    cx="50%"
                                    cy="50%"
                                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                >
                                    {responseData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip
                                    cursor={false}
                                    content={({ active, payload }) => {
                                        if (active && payload && payload.length) {
                                            return (
                                                <Box sx={{ background: 'white', padding: 1, borderRadius: 1, boxShadow: 2 }}>
                                                    <ListItemText primary={`${payload[0].name}: $${payload[0].value}`} />
                                                </Box>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                            {sortedData.slice(0, 5).map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={`${item.Service}: $${item.Cost}`} />
                                </ListItem>
                            ))}
                            {sortedData.length > 5 && <ListItemText primary="..." />}
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CostByService;
