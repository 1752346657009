import React, { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios';
import { List, Box } from '@mui/material';
import CustomListForTagging from '../CustomListForTagging/CustomListForTagging';
import api from '../../Api';

const Tagging = () => {
  const [items, setItems] = useState<string[] | null>(null);
  const [clients, setClients] = useState<string[] | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/api/tags/all');
        setItems(response.data);
        const responseClient = await api.get('/api/client/list');
        setClients(responseClient.data)
      } catch (error) {
        console.log(error)
      }
    };
    fetchData();
  }, []);

  const handleClick = async (item: string) => {
    try {
      setItems(items?.filter(i => i !== item) ?? []);
    } catch (error) {
      console.error('Error tagging item:', error);
    }
  };
  const handleRemoveItem = (item: string) => {
    setItems(items?.filter(i => i !== item) ?? []);
  };

  return (
    <div>
      <Box sx={{ bgcolor: 'background.paper' }}>
        <List>
          {items?.map((item, index) => (
            <CustomListForTagging
              text={item}
              id={index}
              clients={clients}
              onRemove={() => handleRemoveItem(item)}
            />
          ))}
        </List>
      </Box >
    </div >
  )
}

export default Tagging
