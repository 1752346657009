import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import api from '../../../Api';

export default function InstanceIds({ costtype, client, month }: { costtype: string; client: string; month: string }) {
    const columns: GridColDef[] = [
        { field: 'NameOrID', headerName: 'Name or ID', width: 500 },
        { field: 'ResourceType', headerName: 'Resource Type', width: 200 },
        { field: 'Provider', headerName: 'Provider', width: 200 },
        { field: 'ResourceGroup', headerName: 'Resource Group', width: 500 },
    ];

    const [responseData, setResponseData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: AxiosResponse = await api.get(`/api/cost/client/list/instanceids?type=${costtype}&&name=${client}&&month=${month}`);
                setResponseData(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching instance IDs:', error);
            }
        };
        fetchData();
    }, [costtype, client, month]);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={responseData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                getRowId={(row) => row.Id}
                sx={{
                    '& .MuiDataGrid-cell': {
                        borderBottom: '1px solid #e0e0e0',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#f5f5f5',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        backgroundColor: '#f5f5f5',
                    },
                }}
            />
        </div>
    );
}
