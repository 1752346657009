// src/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { JwtPayload, jwtDecode } from "jwt-decode";
interface AuthContextProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    login: (token: string, refreshToken: string) => void;
    logout: () => void;
}
interface ChildrenProps {
    children: React.ReactNode
}
interface ExtendedJwtPayload extends JwtPayload {
    exp: number; // Assuming exp is a number
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

// Utility function to check if a token is expired
function isTokenExpired(token: string): boolean {
    const decodedToken = jwtDecode<ExtendedJwtPayload>(token || '') || null;
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
}

export const AuthProvider: React.FC<ChildrenProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadTokens = async () => {
            const token = localStorage.getItem('token');
            const refreshToken = localStorage.getItem('refreshToken');
            if (token && !isTokenExpired(token)) {
                setIsAuthenticated(true);
                setIsLoading(false);
            } else if (refreshToken && isTokenExpired(refreshToken)) {
                setIsAuthenticated(false);
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken')
            } else {
                setIsLoading(false);
            }
        };
        loadTokens();
    }, []);

    const login = async (token: string, refreshToken: string) => {
        try {
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            setIsAuthenticated(true);
        } catch (error) {
            console.error('Failed to update tokens in localStorage:', error);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken')
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
