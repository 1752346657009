import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import api from '../../Api';

const COLORS = [
  '#00C49F', '#FF5733', '#0088FE', '#FFBB28', '#FF8042',
  '#DAF7A6', '#BDBDC3', '#C70039', '#900C3F', '#581845'
];

export default function CostByService({ costtype, month }: { costtype: string, month: string }) {
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/cost/service?type=${costtype}&&month=${month}`);
        setResponseData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [costtype, month]);

  // Function to select colors from the palette
  const getColor = (index: number): string => COLORS[index % COLORS.length];

  return (
    <Card elevation={3} sx={{ padding: 2, margin: 2 }}>
      <CardHeader
        title="Cost by Service"
        subheader={`For the month of ${month}`}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
        subheaderTypographyProps={{ variant: 'subtitle2' }}
      />
      <CardContent>
        {responseData.length > 0 ? (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={responseData}
              barCategoryGap="20%"
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <XAxis
                dataKey="Service"
                tick={{ fontSize: 12, fontWeight: 500 }}
                label={{ value: 'Service', position: 'insideBottom', offset: -5, fontSize: 14, fontWeight: 600 }}
              />
              <YAxis
                tick={{ fontSize: 12, fontWeight: 500 }}
                label={{ value: 'Cost ($)', angle: -90, position: 'insideLeft', fontSize: 14, fontWeight: 600 }}
              />
              <Tooltip
                contentStyle={{ backgroundColor: '#f5f5f5', borderRadius: '8px', border: 'none' }}
                cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
              />
              {/* <Legend wrapperStyle={{ fontSize: '12px', fontWeight: '500' }} /> */}
              <Bar dataKey="Cost" radius={[10, 10, 0, 0]} fillOpacity={0.8}>
                {responseData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getColor(index)} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1" align="center" color="textSecondary">
            No data available.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
