// ProtectedRoute.tsx
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the import path as necessary

const ProtectedRoute = () => {
    const auth = useAuth();
    if (auth.isLoading) {
        return <div>Loading...</div>;
    }
    return auth.isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
