import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import TotalCost from '../ClientCosts/TotalCost/TotalCost';
import CostByService from '../ClientCosts/CostByService/CostByService';
import CostByRegion from '../ClientCosts/CostByRegion/CostByRegion';
import InstanceIds from '../ClientCosts/InstanceIds/InstanceIds';
import { capitalize } from '@mui/material/utils';

const ClientCostDetails: React.FC = () => {
    const { client, type, month } = useParams();
    const [clientName, setClientName] = useState(capitalize(client || ''));

    return (
        <Box sx={{ minWidth: 'calc(100vw - 110px)', padding: 2 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Cost Details for {clientName}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TotalCost client={client || ''} costtype={type || ''} month={month || ''} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CostByService client={client || ''} costtype={type || ''} month={month || ''} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CostByRegion client={client || ''} costtype={type || ''} month={month || ''} />
                </Grid>
                <Grid item xs={12}>
                    <InstanceIds client={client || ''} costtype={type || ''} month={month || ''} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientCostDetails;
