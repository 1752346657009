import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import api from '../../../Api';

interface TotalCostProps {
    costtype: string;
    client: string;
    month: string;
}

const TotalCost: React.FC<TotalCostProps> = ({ costtype, client, month }) => {
    const [responseData, setResponseData] = useState<string>('0'); // Default to '0' if no data
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: AxiosResponse<{ totalCost: string }> = await api.get(
                    `/api/cost/client/total?type=${costtype}&&name=${client}&&month=${month}`
                );
                setResponseData(response.data.totalCost);
            } catch (error) {
                setError('Error fetching total cost. Please try again later.');
                console.error(error);
            }
        };
        fetchData();
    }, [costtype, client, month]);

    return (
      <Card sx={{ margin: 2, borderRadius: '10px', boxShadow: 3 }}>
            <CardHeader
                title="Total Cost"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
            />
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {error ? (
                    <Typography color="error" variant="body2" align="center">
                        {error}
                    </Typography>
                ) : (
                    <Typography variant="h5" color="primary" align="center">
                        {responseData}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default TotalCost;
