import { Grid, Paper, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddClient from '../AddClient/AddClient';
import { AxiosResponse } from 'axios';
import api from '../../Api';

interface Client {
  name: string;
  rg: string[];
  instanceids?: string[];
}

export default function Clients() {
  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: AxiosResponse<Client[]> = await api.get(`/api/client/details`);
        setClients(response.data);
        console.log('Response Data:', response.data);
        console.log('Type of Response Data:', typeof response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4">Client Management</Typography>

          {/* Table Header */}
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table aria-label="client table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">RG</TableCell>
                  <TableCell align="center">Instance IDs</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((client, index) => (
                  <TableRow key={index}>
                    <TableCell>{client.name}</TableCell>
                    <TableCell align="right">
                      {client.rg.length > 0 ? (
                        client.rg.map((item, index) => (
                          <React.Fragment key={index}>
                            {item}<br />
                          </React.Fragment>
                        ))
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {Array.isArray(client.instanceids) ? (
                        client.instanceids.map((instanceId, index) => (
                          <React.Fragment key={index}>
                            {instanceId}<br />
                          </React.Fragment>
                        ))
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
