import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import CostByClient from '../CostByClient/CostByClient';
import CostByRegion from '../CostByRegion/CostByRegion';
import TotalCost from '../TotalCost/TotalCost';
import axios, { AxiosResponse } from 'axios';
import CostByService from '../CostByService/CostByService';
import api from '../../Api';
import CostByMonth from '../CostByMonth/CostByMonth';
import CostByDate from '../CostByDate/CostByDate';

const apiUrl = 'http://localhost:5000/api/get_cur_data';

interface ApiResponse {
    [key: string]: any;
}

const Home = () => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth(); // Note: getMonth() returns 0-based index
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const [month, setMonth] = useState(monthNames[currentMonthIndex]);
    const [costType, setCostType] = useState('daily');

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedMonth = event.target.value as string;
        setMonth(selectedMonth);
        setCostType(selectedMonth === monthNames[currentMonthIndex] ? 'daily' : 'monthly');
    };

    useEffect(() => {
        console.log(`Month changed to: ${month}`);
    }, [costType,month]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <FormControl sx={{ m: 1, minWidth: 150, bgcolor: '#f5f5f5', borderRadius: 1, boxShadow: 1 }} size="small">
                            <InputLabel id="demo-simple-select-label" sx={{ color: '#555' }}>Month</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={month}
                                label="Month"
                                onChange={handleChange}
                                defaultValue={monthNames[currentMonthIndex]}
                                sx={{
                                    bgcolor: '#fff',
                                    '&:hover': {
                                        bgcolor: '#e0e0e0',
                                    },
                                    '&.Mui-focused .MuiSelect-icon': {
                                        color: '#1976d2',
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 200,
                                            bgcolor: '#f5f5f5',
                                            '& .MuiMenuItem-root:hover': {
                                                backgroundColor: '#cfd8dc',
                                            },
                                        },
                                    },
                                }}
                            >
                                {monthNames.map((monthName, index) => (
                                    <MenuItem key={index} value={monthName}>{monthName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TotalCost costtype={costType} month={month} cloud="general" />
                            </Grid>
                            <Grid item xs={4}>
                                <TotalCost costtype={costType} month={month} cloud="azure" />
                            </Grid>
                            <Grid item xs={4}>
                                <TotalCost costtype={costType} month={month} cloud="aws" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <CostByClient costtype={costType} month={month} />
                    </Grid>
                    <Grid item xs={8}>
                        <CostByService costtype={costType} month={month} />
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CostByMonth />
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CostByDate />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Home;
