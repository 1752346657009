import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import api from '../../Api';

const COLORS = [
  '#00C49F', '#FF5733', '#0088FE', '#FFBB28', '#FF8042',
  '#DAF7A6', '#BDBDC3', '#C70039', '#900C3F', '#581845'
];

export default function CostByDate() {
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/azure/cost/daily`);
        setResponseData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Card elevation={3} sx={{ padding: 2, margin: 2 }}>
      <CardHeader
        title="Cost by Date"
        subheader="Daily cost visualization"
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
        subheaderTypographyProps={{ variant: 'subtitle2' }}
      />
      <CardContent>
        {responseData.length > 0 ? (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={responseData} barCategoryGap="20%" margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <XAxis dataKey="Date" tick={{ fontSize: 12, fontWeight: 500 }} />
              <YAxis tick={{ fontSize: 12, fontWeight: 500 }} />
              <Tooltip
                contentStyle={{ backgroundColor: '#f5f5f5', border: 'none', borderRadius: '8px' }}
                cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
              />
              <Legend wrapperStyle={{ fontSize: '12px', fontWeight: '500' }} />
              <Bar dataKey="Cost" radius={[10, 10, 0, 0]}>
                {responseData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1" align="center" color="textSecondary">
            No data available.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
