// src/api.ts
import axios from 'axios';

const apiUrl = process.env.NODE_ENV === 'production' ? `${window.location.origin}` : 'http://127.0.0.1:5000';

const api = axios.create({
  baseURL: apiUrl,
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          // Handle case where refresh token is missing
          throw new Error('Refresh token is missing');
        }
        const response = await api.post('/api/refresh-token', { refreshToken });
        if(response.status !== 200) {
          throw new error('Failed to refresh token')
        } else {
          const { token } = response.data;
          localStorage.setItem('token', token);
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        }

      } catch (error) {
        console.error('Error refreshing token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken')
        window.location.href = '/login'; 
      }
    }

    return Promise.reject(error);
  }
);


export default api;
