import { Box, CardHeader, CircularProgress, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import api from '../../Api';
import AWSLogo from '../../images/aws.png';
import AZURELogo from '../../images/azure.png';

export default function TotalCost({ costtype, month, cloud }: { costtype: string, month: string, cloud: string }) {
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showAWSLogo, setShowAWSLogo] = useState(false);
  const [showAZURELogo, setShowAZURELogo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null); // Clear any previous errors
      try {
        const response: AxiosResponse = await api.get(`/api/cost/total?type=${costtype}&month=${month}&cloud=${cloud}`);
        if (!response || !response.data) {
          throw new Error('Invalid response data');
        }
        setResponseData(response.data.totalCost);
        setShowAWSLogo(cloud === 'aws');
        setShowAZURELogo(cloud === 'azure');
      } catch (error) {
        console.error('Error fetching total cost:', error);
        setError('An error occurred while fetching the data.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [costtype, month, cloud]);

  return (
    <Card sx={{ minWidth: 275, boxShadow: 3 }}>
      {/* <CardHeader title="Total Cloud Cost" /> */}
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {!showAWSLogo && !showAZURELogo && (
          <Typography variant="h6" color="textSecondary">Total Cloud Cost</Typography>
        )}
        {showAWSLogo && (
          <Box sx={{ mt: 2 }}>
            <img src={AWSLogo} alt="AWS Logo" style={{ width: 50, height: 50 }} />
          </Box>
        )}
        {showAZURELogo && (
          <Box sx={{ mt: 2 }}>
            <img src={AZURELogo} alt="AZURE Logo" style={{ width: 50, height: 50 }} />
          </Box>
        )}
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <CircularProgress size={24} />
            <Typography variant="body2" sx={{ ml: 1 }}>Loading...</Typography>
          </Box>
        ) : error ? (
          <Typography variant="body2" color="error">{error}</Typography>
        ) : (
          <Typography variant="h5" sx={{ mt: 2 }}>
            {responseData || 'No data available'}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
